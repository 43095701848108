// import React, { useState } from "react";
import React, { Fragment, useEffect, useState } from "react";
import moment from "moment";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Pagination from "@mui/material/Pagination";
import { Box, Tab, Tabs, tabsClasses } from "@mui/material";
import ViewPDF from "../../../ViewPDF/ViewPDF";
import NavBarItem from "../../../Navbar/NavBarItem";
import SwapColor from "../../../SwapColor/SwapColor";
import ShareholdersCard from "../../ShareholdersCard";
import HeaderBanner from "../../../HeaderCore/HeaderBanner";
import { baseUrl } from "../../../../services/Environment";
import useStrAPIPage from "../../../../hooks/useStrAPIPage";

function Information({ lang }) {
  const [cValue, setCValue] = useState(0);
  const [sValue, setSValue] = useState(0);
  const [exValue, setExValue] = useState(0);
  const [filterCItems, setFilterCItems] = useState([]);
  const [filterSItems, setFilterSItems] = useState([]);
  const [filterExItems, setFilterExItems] = useState([]);
  const [currentCPage, setCurrentCPage] = useState(1);
  const [currentSPage, setCurrentSPage] = useState(1);
  const [currentExPage, setCurrentExPage] = useState(1);
  const { PageAPI, loadingPage, getAPIPage } = useStrAPIPage();
  const [YearNow, setYearNow] = useState(

  lang === "en"
      ? moment().format("YYYY")
      : moment().add(543, `year`).format("YYYY")
  );

  const currentYear =
    lang === "en"
      ? moment().format("YYYY")
      : moment().add(543, "year").format("YYYY");

  // Convert currentYear to a number for calculation
  const startYear = parseInt(currentYear) - 6;
  const endYear = parseInt(currentYear);

  // Generate an array of years
  const years = Array.from({ length: endYear - startYear + 1 }, (v, i) =>
    (startYear + i).toString()
  );

  const [CompanyLists, setCompanyLists] = useState([]);
  const [ShareholderList, setShareholderList] = useState([]);
  const [ExtraordinaryList, setExtraordinaryList] = useState([]);

  useEffect(() => {
    if (lang !== undefined) {
      setCompanyLists([]);
      setShareholderList([]);
      setExtraordinaryList([]);
      getAPIPage(
        "shareholding-information",
        lang,
        "&populate[0]=company_snapshot.details.file&populate[1]=shareholders_meeting.details.file&populate[2]=extraordinary_general_meeting.details.file"
      ); //populate
    }
  }, [lang]);

  useEffect(() => {
    //  console.log('loading',loading)
    // console.log('company_snapshot',CompanyLists,PageAPI?.data.attributes.company_snapshot)
    if (CompanyLists) {
      // console.log('company_snapshot',PageAPI?.data.attributes.company_snapshot)
      var yearData = PageAPI?.data.attributes.company_snapshot.find(
        (item) => item.year === `${YearNow}`
      );

      if (yearData) {
        const renderCompanyLists = (CompanyLists) => {
          return (
            CompanyLists.details.map((CompanyList) => (
              <ViewPDF
                // key={statement.id} // Use a unique key for each component
                title={CompanyList.name} // Use statement name or other appropriate field
                year={yearData.attributes.year} // Assuming you want to use the year from yearData
                // href={baseUrl + CompanyList.file.data.attributes.url}
                href={
                  CompanyList.file.data?.attributes.url == undefined
                    ? "/404"
                    : baseUrl + CompanyList.file.data?.attributes.url
                }
              />
            )) || [<ViewPDF title={"--------------"} value="-------" />]
          );
        };
        // console.log('yearData',yearData)
        const renderedCompanyLists = renderCompanyLists(yearData);
        setCompanyLists(renderedCompanyLists);
      } else {
        PageAPI?.data.attributes.company_snapshot.forEach((item) => {
          // console.log('item',item)
          item.details?.forEach((item_1) => {
            // console.log('item_1',item_1)
            // console.log('item_1',baseUrl,item_1.file)
            CompanyLists.push([
              <ViewPDF
                // date={"09/08/2567"}
                href={baseUrl + item_1.file?.data?.attributes.url}
                year={item.year}
                title={item_1.name}
              />,
            ]);
          });
        });

        // setCompanyLists([<ViewPDF title={"--------------"} value="-------" />]);
      }
    } else {
      setCompanyLists([<ViewPDF title={"--------------"} value="-------" />]);
    }
  // }, [PageAPI, loadingPage,lang]);

  // useEffect(() => {
    // console.log(PageAPI?.data.attributes.shareholders_meeting[0].details[0].name)
    if (ShareholderList) {
      var yearData_shareholders_meeting =
        PageAPI?.data.attributes.shareholders_meeting.find(
          (item) => item.year === `${YearNow}`
        );

      if (yearData_shareholders_meeting) {
        const renderShareholderLists = (ShareholderLists_arr) => {
          // console.log('ShareholderLists_arr',ShareholderLists_arr)
          return (
            ShareholderLists_arr.details.map((ShareholderList_arr) => (
              // console.log(ShareholderList_arr)
              <ViewPDF
                title={ShareholderList_arr.name}
                year={yearData_shareholders_meeting.year}
                href={baseUrl + ShareholderList_arr.file.data?.attributes.url}
                url_link={ShareholderList_arr.url}
              />
            )) || [<ViewPDF title={"--------------"} value="-------" />]
          );
        };
        const renderShareholderList = renderShareholderLists(
          yearData_shareholders_meeting
        );
        setShareholderList(renderShareholderList);
      } else {
        PageAPI?.data.attributes.shareholders_meeting.forEach((item) => {
          // console.log('item',item)
          item.details?.forEach((item_1) => {
            // console.log('item_1',item_1)
            // console.log('item_1',baseUrl,item_1.file)
            ShareholderList.push([
              <ViewPDF
                // date={"09/08/2567"}
                href={baseUrl + item_1.file?.data?.attributes.url}
                year={item.year}
                title={item_1.name}
                url_link={item_1.url}
              />,
            ]);
          });
        });
        // setShareholderList([<ViewPDF title={"--------------"} value="-------" />]);
      }
    } else {
      setShareholderList([
        <ViewPDF title={"--------------"} value="-------" />,
      ]);
    }
  // }, [PageAPI, loadingPage,lang]);

  // useEffect(() => {
    // console.log(PageAPI?.data.attributes.shareholders_meeting)
    if (ExtraordinaryList) {
      var yearData_shareholders_meeting =
        PageAPI?.data.attributes.extraordinary_general_meeting.find(
          (item) => item.year === `${YearNow}`
        );

      if (yearData_shareholders_meeting) {
        const renderExtraordinaryList = (ExtraordinaryList_arrs) => {
          // console.log('ShareholderLists_arr',ShareholderLists_arr)
          return (
            ExtraordinaryList_arrs.details.map((ExtraordinaryList_arr) => (
              // console.log('ExtraordinaryList_arr',ExtraordinaryList_arr)
              <ViewPDF
                title={ExtraordinaryList_arr.name}
                year={yearData_shareholders_meeting.year}
                href={baseUrl + ExtraordinaryList_arr.file.data.attributes.url}
                url_link={ExtraordinaryList_arr.url}
              />
            )) || [<ViewPDF title={"--------------"} year="------" />]
          );
        };
        const renderShareholderList = renderExtraordinaryList(
          yearData_shareholders_meeting
        );
        setExtraordinaryList(renderShareholderList);
      } else {
        PageAPI?.data.attributes.extraordinary_general_meeting.forEach(
          (item) => {
            // console.log('item',item)
            item.details?.forEach((item_1) => {
              // console.log('item_1',item_1)
              // console.log('item_1',baseUrl,item_1.file)
              ExtraordinaryList.push([
                <ViewPDF
                  // date={"09/08/2567"}
                  href={baseUrl + item_1.file?.data?.attributes.url}
                  year={item.year}
                  title={item_1.name}
                  url_link={item_1.url}
                />,
              ]);
            });
          }
        );
        // setExtraordinaryList([<ViewPDF title={"--------------"} year="------"  />]);
      }
    } else {
      setExtraordinaryList([
        <ViewPDF title={"--------------"} year="------" />,
      ]);
    }
  }, [PageAPI]);

  function scrollToElement(id) {
    let offset = 90; // ระยะ offset ที่ต้องการ
    const element = document.getElementById(id);
    const offsetTop = element.offsetTop - offset; // คำนวณระยะ offset ที่ต้องการเลื่อน

    window.scrollTo({
      top: offsetTop,
      behavior: "smooth", // เลื่อนโดยการ animate ให้เรียบร้อย
    });
  }

  //------ Year --------//
  const changeCPDF = (selectedYear) => {
    const yearData = PageAPI?.data.attributes.company_snapshot.find(
      (item) => item.year === `${selectedYear}`
    );
    // console.log('selectedYear',selectedYear,yearData)
    if (yearData) {
      const renderCompanyLists = (CompanyLists) => {
        return (
          CompanyLists.details.map((CompanyList) => (
            <ViewPDF
              title={CompanyList.name} // Use statement name or other appropriate field
              year={yearData.year} // Assuming you want to use the year from yearData
              href={baseUrl + CompanyList.file.data?.attributes.url}
            />
          )) || [<ViewPDF title={"--------------"} value="-------" />]
        );
      };
      // console.log('yearData',yearData)
      const renderedCompanyLists = renderCompanyLists(yearData);
      setCompanyLists(renderedCompanyLists);
    } else {
      setCompanyLists([<ViewPDF title={"--------------"} value="-------" />]);
    }
  };

  const changeSPDF = (selectedYear) => {
    // console.log(selectedYear)
    var yearData_shareholders_meeting =
      PageAPI?.data.attributes.shareholders_meeting.find(
        (item) => item.year === `${selectedYear}`
      );

    if (yearData_shareholders_meeting) {
      const renderShareholderLists = (ShareholderLists_arr) => {
        // console.log('ShareholderLists_arr',ShareholderLists_arr)
        return (
          ShareholderLists_arr.details.map((ShareholderList_arr) => (
            // console.log(ShareholderList_arr.file)
            <ViewPDF
              title={ShareholderList_arr.name}
              year={yearData_shareholders_meeting.year}
              href={baseUrl + ShareholderList_arr.file.data?.attributes.url}
              url_link={ShareholderList_arr.url}
            />
          )) || [<ViewPDF title={"--------------"} value="-------" />]
        );
      };
      const renderShareholderList = renderShareholderLists(
        yearData_shareholders_meeting
      );
      setShareholderList(renderShareholderList);
    } else {
      setShareholderList([
        <ViewPDF title={"--------------"} value="-------" />,
      ]);
    }
    // const selectedShareholderPDFs = ShareholderList.filter(
    //   (pdf) => pdf.props.year === selectedYear
    // );
    // setFilterSItems(selectedShareholderPDFs);
  };

  const changeExPDF = (selectedYear) => {
    // console.log(selectedYear)
    var yearData_shareholders_meeting =
      PageAPI?.data.attributes.extraordinary_general_meeting.find(
        (item) => item.year === `${selectedYear}`
      );

    if (yearData_shareholders_meeting) {
      const renderExtraordinaryList = (ExtraordinaryList_arrs) => {
        // console.log('ShareholderLists_arr',ShareholderLists_arr)
        return (
          ExtraordinaryList_arrs.details.map((ExtraordinaryList_arr) => (
            <ViewPDF
              title={ExtraordinaryList_arr.name}
              year={yearData_shareholders_meeting.year}
              href={baseUrl + ExtraordinaryList_arr.file.data.attributes.url}
              url_link={ExtraordinaryList_arr.url}
            />
          )) || [<ViewPDF title={"--------------"} year="------" />]
        );
      };
      const renderShareholderList = renderExtraordinaryList(
        yearData_shareholders_meeting
      );
      setExtraordinaryList(renderShareholderList);
    } else {
      setExtraordinaryList([
        <ViewPDF title={"--------------"} year="------" />,
      ]);
    }

    // const selectedExtraordinaryPDFs = ExtraordinaryList.filter(
    //   (pdf) => pdf.props.year === selectedYear
    // );
    // setFilterExItems(selectedExtraordinaryPDFs);
  };

  const changeCompanyYear = (selectedYear, newValue) => {
    setCurrentCPage(1);
    setCValue(newValue);
    changeCPDF(newValue);
  };
  const changeShareholderYear = (selectedYear, newValue) => {
    setCurrentSPage(1);
    setSValue(newValue);
    changeSPDF(newValue);
  };
  const changeExtraordinaryYear = (selectedYear, newValue) => {
    setCurrentExPage(1);
    setExValue(newValue);
    changeExPDF(newValue);
  };
  // console.log("filterCItems", filterCItems);
  // console.log("filterSItems", filterSItems);
  // console.log("filterExItems", filterExItems);

  //------ CompanyLists --------//
  // เปลี่ยน handleChange เพื่อให้เปลี่ยนหน้าเมื่อมีการคลิก Pagination
  const handleChange = (event, value) => {
    setCurrentCPage(value);
    scrollToElement("Company");
  };
  const itemsPerPage = 4;
  const pageCCount = Math.ceil(CompanyLists.length / itemsPerPage);
  const paginatedCompanyLists = Array.from(
    { length: pageCCount },
    (_, index) => {
      const startIndex = index * itemsPerPage;
      return CompanyLists.slice(startIndex, startIndex + itemsPerPage);
    }
  );

  //------ Shareholder's  --------//
  const handleShareholderChange = (event, value) => {
    setCurrentSPage(value);
    scrollToElement("Meeting");
  };
  const itemsPerSPage = 8;
  const pageSCount = Math.ceil(ShareholderList.length / itemsPerSPage);
  const paginatedShareholderList = Array.from(
    { length: pageSCount },
    (_, index) => {
      const startIndex = index * itemsPerSPage;
      return ShareholderList.slice(startIndex, startIndex + itemsPerSPage);
    }
  );
  //------ ExtraordinaryList --------//
  const handleExtraordinaryChange = (event, value) => {
    setCurrentExPage(value);
    scrollToElement("Extraordinary");
  };
  const itemsPerExPage = 8;
  const pageExCount = Math.ceil(ExtraordinaryList.length / itemsPerExPage);
  const paginatedExLists = Array.from({ length: pageExCount }, (_, index) => {
    const startIndex = index * itemsPerExPage;
    return ExtraordinaryList.slice(startIndex, startIndex + itemsPerExPage);
  });

  return (
    <>
      <nav className="mt-5 mobile_mt20 d-flex  gap-4 align-items-center xl-col justify-content-center">
        <div className="d-flex gap-3">
          <NavBarItem id={"Shareholder"}>
            {lang === "th"
              ? `ข้อมูลผู้ถือหุ้น`
              : `Shareholder Information
`}
          </NavBarItem>
          <NavBarItem id={"General"}>
            {lang === "th"
              ? `ข้อมูลพื้นฐานการลงทุน `
              : `General Information and Reference Persons`}
          </NavBarItem>
          <NavBarItem id={"Meeting"}>
            {lang === "th" ? `การประชุมผู้ถือหุ้น ` : `Shareholder's Meeting`}
          </NavBarItem>
        </div>
      </nav>
      {/*------- ข้อมูลสำหรับผู้ถือหุ้น ------*/}
      <section id="Shareholder" className="container pt-5">
        <h1 className="title-line fw-bold">
          {lang === "th"
            ? `ข้อมูลสำหรับผู้ถือหุ้น `
            : `Shareholder Information`}
        </h1>

        {/*------- โครงสร้างผู้ถือหุ้น ------*/}
        <section className="pt-5 container">
          <div className="mx-auto" style={{ width: "95%" }}>
            <h2 className="fw-bold mb-4">
              {lang === "th" ? `โครงสร้างผู้ถือหุ้น` : `Major Shareholders`}
            </h2>
            <div className="row">
              <div className="col-lg-6 ">
                <div
                  className="bg-gray d-flex justify-content-center align-items-center"
                  style={{ height: "80px" }}
                >
                  <h5 className="m-0 text-center">
                    {lang === "th"
                      ? `ผู้ถือหุ้นรายย่อย ณ วันที่ 14/3/2566 `
                      : `Free Float  As of 14/03/2023 `}{" "}
                  </h5>
                </div>

                <ShareholdersCard
                  src="/images/Logo/นักลงทุนสัมพันธ์ icon New-37_0.png"
                  lists={
                    lang === "th"
                      ? ["จำนวนผู้ถือหุ้นรายย่อย", `(Free float)`]
                      : [`Minor Shareholders`, `(Free float)`]
                  }
                  num={4212}
                  decimals={0}
                  // classes="one"
                />
                <ShareholdersCard
                  src="/images/Logo/นักลงทุนสัมพันธ์ icon New-40_0.png"
                  lists={
                    lang === "th"
                      ? [
                          `% การถือหุ้น`,
                          `ของผู้ถือหุ้นรายย่อย`,
                          ` (% Free float)`,
                        ]
                      : [
                          `% Shares in`,
                          `Minor Shareholders `,
                          ` (% Free float) `,
                        ]
                  }
                  num={74.06}
                  decimals={2}
                  // classes="three"
                />
              </div>
              <div className="col-lg-6 ">
                <div
                  className="bg-gray d-flex justify-content-center align-items-center"
                  style={{ height: "80px" }}
                >
                  {lang === "th" ? (
                    <h5 className="m-0 text-center">
                      ภาพรวมข้อมูลผู้ถือหุ้น ณ วันที่ 14/03/2566 <br />
                      ประเภทการปิดสมุดทะเบียน : XM{" "}
                    </h5>
                  ) : (
                    <h5 className="m-0 text-center">
                      Over view As of 14/03/2023 <br />
                      Right Type : XM{" "}
                    </h5>
                  )}
                </div>
                <ShareholdersCard
                  src="/images/Logo/นักลงทุนสัมพันธ์ icon New-38_0.png"
                  lists={
                    lang === "th"
                      ? [`จำนวนผู้ถือหุ้น`, `ทั้งหมด`]
                      : [`Total `, `Shareholders`]
                  }
                  num={4621}
                  // classes={"two"}
                />
                <ShareholdersCard
                  src="/images/Logo/นักลงทุนสัมพันธ์ icon New-39_0.png"
                  lists={
                    lang === "th"
                      ? [`% การถือหุ้น`, `แบบไร้ใบหุ้น`, ` `]
                      : [`% Shares in`, `Scripless`]
                  }
                  num={100}
                  decimals={2}

                  // classes={"four"}
                />
              </div>
            </div>
          </div>
        </section>

        <div id="Company" className="mx-auto" style={{ width: "95%" }}>
          <section id="market" className="pt-5 container">
            <h2 className="fw-bold">
              {lang === "th" ? "ภาพรวมบริษัท" : "Company Snapshot"}
            </h2>
            <Box
              className="mx-auto mt-3 mb-5"
              sx={{
                maxWidth: { xs: 420, sm: 580 },
                bgcolor: "background.paper",
              }}
            >
              <Tabs
                value={cValue}
                onChange={changeCompanyYear}
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
                aria-label="visible arrows tabs example"
                sx={{
                  [`& .${tabsClasses.scrollButtons}`]: {
                    "&.Mui-disabled": { opacity: 0.3 },
                  },
                }}
              >
                {years.map((year) => (
                  <Tab className="fs-4" label={year} value={year} key={year} />
                ))}
              </Tabs>
            </Box>

            <Stack>
              <Typography>
                <div>
                  {paginatedCompanyLists[currentCPage - 1]?.map(
                    (list, index) => list
                  )}
                </div>
              </Typography>
              <Pagination
                count={pageCCount}
                page={currentCPage}
                onChange={handleChange}
                hidePrevButton
                hideNextButton
                shape="rounded"
                className="d-flex justify-content-end pt-4 pe-4"
                size="large"
                sx={{
                  "& .Mui-selected": { bgcolor: "#cccccc !important" },
                  "& button": { bgcolor: "#e6e6e6" },
                  "& button:hover": { bgcolor: "lightgray" },
                }}
              ></Pagination>
            </Stack>
          </section>
        </div>
      </section>

      {/*------- ข้อมูลพื้นฐานการลงทุน ------*/}

      {/* {lang === "th" ? ` ` : ` `} */}
      <section id="General" className="pt-5 ">
        <div className="container">
          <h2 className="fw-bold mx-auto mb-5" style={{ width: "95% " }}>
            {lang === "th"
              ? `ข้อมูลพื้นฐานการลงทุน`
              : `General Information and Reference Persons `}
          </h2>
        </div>

        <div className="mx-auto">
          <SwapColor
            title={lang === "th" ? "ชื่อบริษัท :" : "Company :"}
            lists={
              lang === "th"
                ? [
                    `บริษัท เอ็นซีแอล อินเตอร์เนชั่นแนล โลจิสติกส์ จำกัด (มหาชน)`,
                    `NCL International Logistics Public Company Limited`,
                  ]
                : [`NCL International Logistics Public Company Limited`]
            }
          />
          <SwapColor
            title={lang === "th" ? "ชื่อย่อหลักทรัพย์ :" : "Symbol :"}
            lists={[`NCL`]}
          />
          <SwapColor
            title={lang === "th" ? "เลขทะเบียนบริษัท :" : "Registered No :"}
            lists={[`0107556000434`]}
          />
          <SwapColor
            title={lang === "th" ? "ประเภทธุรกิจ :" : "Type of Business :"}
            lists={
              lang === "th"
                ? [
                    `บริการจัดการโลจิสติกส์ครบวงจร (Logistics Solution Provider) โดยแบ่งเป็น 2 กลุ่มธุรกิจ ได้แก่ การให้บริการจัดการขนส่งระหว่างประเทศ และให้บริการขนส่งในประเทศ`,
                  ]
                : ["Logistics Solution Provider"]
            }
          />
          <SwapColor
            title={lang === "th" ? "ทุนจดทะเบียน :" : "Registered Capital :"}
            lists={
              lang === "th" ? [`132,058,498.25 บาท`] : ["132,058,498.25 Baht"]
            }
          />
          <SwapColor
            title={
              lang === "th" ? "ทุนจดทะเบียนชำระแล้ว :" : "Paid-up Capital :"
            }
            lists={
              lang === "th" ? ["132,058,498.25 บาท"] : ["132,058,498.25 Baht"]
            }
          />
          <SwapColor
            title={"จำนวนหุ้นที่จำหน่ายแล้วทั้งหมด :"}
            lists={
              lang === "th" ? [`528,233,993 หุ้น`] : ["528,233,993 Shares"]
            }
          />
          <SwapColor
            title={lang === "th" ? "มูลค่าที่ตราไว้หุ้นละ :" : "Par Value :"}
            lists={lang === "th" ? ["0.25 บาท"] : ["0.25 Baht per share"]}
          />
          <SwapColor
            title={lang === "th" ? "ที่ตั้งสำนักงานใหญ่ :" : "Head Office :"}
            lists={
              lang === "th"
                ? [
                    `56/9-10 ซอยสมเด็จพระเจ้าตากสิน 12/1 ถนนสมเด็จพระเจ้าตากสิน แขวงบุคคโล เขตธนบุรี กรุงเทพฯ 10600`,
                  ]
                : [
                    `56/9-10 Soi Som det Phra Chao Tak Sin 12/1, Som det Phra Chao Tak Sin Road, Bukkalo, Thonburi Bangkok 10600`,
                  ]
            }
          />
          <SwapColor
            title={lang === "th" ? "โทรศัพท์ :" : "Telephone :"}
            lists={[`02-459-4945`]}
          />
          <SwapColor
            title={lang === "th" ? "เลขานุการบริษัท :" : "Company Secretary :"}
            lists={
              lang === "th"
                ? [
                    `โทรศัพท์ : 02-459-4945 ต่อ 1202`,
                    `อีเมล์ : Ratirat.c@nclthailand.com`,
                  ]
                : [
                    `Telephone :  02-459-4945 ext. 1202`,
                    `Email: Ratirat.c@nclthailand.com`,
                  ]
            }
          />
          <SwapColor
            title={lang === "th" ? "เว็ปไซต์ :" : "Website :"}
            lists={[`www.nclthailand.com`]}
          />
          <SwapColor
            title={lang === `th` ? "ผู้สอบบัญชี :" : "Auditor :"}
            lists={
              lang === "th"
                ? [
                    `บริษัท เน็คเซีย เอเอสวี (ประเทศำไทย) จำกัด`,
                    `47 ซอยโชคชัยจงจำเริญ ถนนพพระราม 3`,
                    `แขวงบางโพงพาง เขตยานนาวา`,
                    `กรุงเทพมหานคร 10120`,
                    `โทรศัพท์: 02-294-8504`,
                  ]
                : [
                    `Nexia ASV (Thailand) Co., Ltd.`,
                    `47 Chok Chai Chong Charoen Soi, Rama 3 Road`,
                    `Bang Phongphang Subdistrict, Yannawa District`,
                    `Bangkok 10120`,
                    `Phone: 02-294-8504`,
                  ]
            }
          />
          <SwapColor
            title={lang === "th" ? "ที่ปรึกษากฎหมาย :" : "Legal Advisor :"}
            lists={
              lang === "th"
                ? [
                    `สำนักงานกฎหมายจักรกฤษณ์ แอนด์ แอสโซซิเอทส์`,
                    `89 ห้อง 1507 ชั้น 15 ตึก AIA Capital Center ถนนรัชดาภิเษก `,
                    `แขวงดินแดง เขตดินแดง กรุงเทพฯ 10400`,
                    `โทรศัพท์ : 081-000-0828`,
                  ]
                : [
                    `Chakrit & Associates Law Office`,
                    `89 Room 1507, 15th Floor AIA Capital Center Building Ratchadaphisek Road `,
                    `Din Daeng Subdistrict Din Daeng District, Bangkok 10400`,
                    `Telephone: 081-000-0828`,
                  ]
            }
          />
          {/* <SwapColor
            title={lang === "th" ? "โทรศัพท์ :" : "Telephone :"}
            lists={[`02-4343132`]}
          /> */}
          {/* <SwapColor
            title={lang === "th" ? "โทรสาร :" : "Facsimile :"}
            lists={
              lang === "th"
                ? [
                    `02-4343133`,
                    `สำนักงานกฎหมายจักรกฤษณ์ แอนด์ แอสโซซิเอทส์`,
                    `89 ห้อง 1507 ชั้น 15 ตึก AIA Capital Center ถนนรัชดาภิเษก `,
                    `แขวงดินแดง เขตดินแดง กรุงเทพฯ 10400`,
                    `โทรศัพท์ : 081-000-0828`,
                  ]
                : [
                    `02-4343133`,
                    `Chakrit & Associates Law Office`,
                    `89 Room 1507, 15th Floor AIA Capital Center Building Ratchadaphisek Road `,
                    `Din Daeng Subdistrict Din Daeng District, Bangkok 10400`,
                    `Telephone: 081-000-0828`,
                  ]
            }
          /> */}
        </div>
      </section>
      <div className="py-5">
        <HeaderBanner
          img={"/images/banner/นักลงทุนสัมพันธ์-ข้อมูลสำหรับผู้ถือหุ้น-2.jpg"}
          imgMobile={
            "/images/banner/นักลงทุนสัมพันธ์-ข้อมูลสำหรับผู้ถือหุ้น-Mobile-2.jpg"
          }
        />
      </div>
      {/*------- การประชุมสามัญผู้ถือหุ้น ------*/}
      <section id="Meeting" className="pt-5 container">
        <div className="mx-auto" style={{ width: "95%" }}>
          <div>
            <h2 className="fw-bold">
              {lang === "th"
                ? `การประชุมสามัญผู้ถือหุ้น`
                : `Shareholder's Meeting`}
            </h2>
            <Box
              className="mx-auto mt-3 mb-5"
              sx={{
                maxWidth: { xs: 420, sm: 580 },
                bgcolor: "background.paper",
              }}
            >
              <Tabs
                value={sValue}
                onChange={changeShareholderYear}
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
                aria-label="visible arrows tabs example"
                sx={{
                  [`& .${tabsClasses.scrollButtons}`]: {
                    "&.Mui-disabled": { opacity: 0.3 },
                  },
                }}
              >
                {years.map((year) => (
                  <Tab className="fs-4" label={year} value={year} key={year} />
                ))}
                {/* <Tab className="fs-4" label="2561" value="2561" />
                <Tab className="fs-4" label="2562" value="2562" />
                <Tab className="fs-4" label="2563" value="2563" />
                <Tab className="fs-4" label="2564" value="2564" />
                <Tab className="fs-4" label="2565" value="2565" />
                <Tab className="fs-4" label="2566" value="2566" />
                <Tab className="fs-4" label="2567" value="2567" /> */}
              </Tabs>
            </Box>

            <Stack>
              <Typography>
                <div>
                  {paginatedShareholderList[currentSPage - 1]?.map(
                    (list, index) => list
                  )}
                </div>
              </Typography>
              <Pagination
                count={pageSCount}
                page={currentSPage}
                onChange={handleShareholderChange}
                hidePrevButton
                hideNextButton
                shape="rounded"
                className="d-flex justify-content-end pt-4 pe-4"
                size="large"
                sx={{
                  "& .Mui-selected": { bgcolor: "#cccccc !important" },
                  "& button": { bgcolor: "#e6e6e6" },
                  "& button:hover": { bgcolor: "lightgray" },
                }}
              ></Pagination>
            </Stack>
          </div>
        </div>
      </section>

      {/*------- การประชุมวิสามัญผู้ถือหุ้น ------*/}
      <section id="Extraordinary" className="py-5 container">
        <div className="mx-auto" style={{ width: "95%" }}>
          <h2 className="fw-bold">
            {lang === "th"
              ? `การประชุมวิสามัญผู้ถือหุ้น`
              : `Extraordinary General Meeting`}
          </h2>

          <Box
            className="mx-auto mt-3 mb-5"
            sx={{ maxWidth: { xs: 420, sm: 580 }, bgcolor: "background.paper" }}
          >
            <Tabs
              value={exValue}
              onChange={changeExtraordinaryYear}
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
              aria-label="visible arrows tabs example"
              sx={{
                [`& .${tabsClasses.scrollButtons}`]: {
                  "&.Mui-disabled": { opacity: 0.3 },
                },
              }}
            >
              {/* <Tab className="fs-4" label="2561" value="2561" />
              <Tab className="fs-4" label="2562" value="2562" />
              <Tab className="fs-4" label="2563" value="2563" />
              <Tab className="fs-4" label="2564" value="2564" />
              <Tab className="fs-4" label="2565" value="2565" />
              <Tab className="fs-4" label="2566" value="2566" />
              <Tab className="fs-4" label="2567" value="2567" /> */}
              {years.map((year) => (
                <Tab className="fs-4" label={year} value={year} key={year} />
              ))}
            </Tabs>
          </Box>

          <Stack>
            <Typography>
              <div>
                {paginatedExLists[currentExPage - 1]?.map(
                  (list, index) => list
                )}
              </div>
            </Typography>
            <Pagination
              count={pageExCount}
              page={currentExPage}
              onChange={handleExtraordinaryChange}
              hidePrevButton
              hideNextButton
              shape="rounded"
              className="d-flex justify-content-end pt-4 pe-4"
              size="large"
              sx={{
                "& .Mui-selected": { bgcolor: "#cccccc !important" },
                "& button": { bgcolor: "#e6e6e6" },
                "& button:hover": { bgcolor: "lightgray" },
              }}
            ></Pagination>
          </Stack>
        </div>
      </section>

      {/*------- โครงสร้างผู้ถือหุ้น ------*/}
      {/* <section className="section container">
        <div className="mx-auto" style={{ width: "95%" }}>
          <h2 className="fw-bold mb-4">
            {lang === "th" ? `โครงสร้างผู้ถือหุ้น` : `Major Shareholders`}
          </h2>
          <div className="row">
            <div className="col-lg-6 ">
              <div
                className="bg-gray d-flex justify-content-center align-items-center"
                style={{ height: "80px" }}
              >
                <h5 className="m-0 text-center">
                  {lang === "th"
                    ? ` ผู้ถือหุ้นรายย่อย ณ วันที่ 14/3/2566 `
                    : `Free Float  As of 14/03/2023 `}{" "}
                </h5>
              </div>

              <ShareholdersCard
                src="/images/Logo/นักลงทุนสัมพันธ์ icon New-37_0.png"
                lists={
                  lang === "th"
                    ? ["จำนวนผู้ถือหุ้นรายย่อย", `(Free float)`]
                    : [`Minor Shareholders`, `(Free float)`]
                }
                num="4,"
                classes="one"
              />
              <ShareholdersCard
                src="/images/Logo/นักลงทุนสัมพันธ์ icon New-40_0.png"
                lists={
                  lang === "th"
                    ? [
                        `% การถือหุ้น`,
                        `ของผู้ถือหุ้นรายย่อย`,
                        ` (% Free float)`,
                      ]
                    : [`% Shares in`, `Minor Shareholders `, ` (% Free float) `]
                }
                // 74.06
                num={""}
                classes="three"
              />
            </div>
            <div className="col-lg-6 ">
              <div
                className="bg-gray d-flex justify-content-center align-items-center"
                style={{ height: "80px" }}
              >
                {lang === "th" ? (
                  <h5 className="m-0 text-center">
                    ภาพรวมข้อมูลผู้ถือหุ้น ณ วันที่ 14/03/2566 <br />
                    ประเภทการปิดสมุดทะเบียน : XM{" "}
                  </h5>
                ) : (
                  <h5 className="m-0 text-center">
                    Over view As of 14/03/2023 <br />
                    Right Type : XM{" "}
                  </h5>
                )}
              </div>
              <ShareholdersCard
                src="/images/Logo/นักลงทุนสัมพันธ์ icon New-38_0.png"
                lists={
                  lang === "th"
                    ? [`จำนวนผู้ถือหุ้น`, `ทั้งหมด`]
                    : [`Total `, `Shareholders`]
                }
                num={"4,"}
                classes={"two"}
              />
              <ShareholdersCard
                src="/images/Logo/นักลงทุนสัมพันธ์ icon New-39_0.png"
                lists={
                  lang === "th"
                    ? [`% การถือหุ้น`, `แบบไร้ใบหุ้น`, ` `]
                    : [`% Shares in`, `Scripless`]
                }
                num={""}
                classes={"four"}
              />
            </div>
          </div>
        </div>
      </section> */}

      {/*------- table ------*/}

      <section className="container section">
        <div className="mx-auto" style={{ width: "95%" }}>
          <h5 className="mb-4">
            {lang === "th"
              ? `หมายเหตุ: ข้อมูลผู้ถือหุ้นปรับตามรอบของการปิดสมุดทะเบียน `
              : `Remark: Shareholder's information, adjusted from the book closing date `}
          </h5>
          <div className="mx-auto " style={{ overflowX: "auto", width: "95%" }}>
            <table className="table_border  mx-auto">
              <tr className="bg-blue ">
                <th className="text-white tableB">
                  {lang === "th" ? `ลำดับ ` : `Rank `}
                </th>
                <th className="text-white ">
                  {lang === "th" ? `ผู้ถือหุ้นรายใหญ่ ` : ` Major Shareholders`}
                </th>
                <th className="text-white">
                  {lang === "th" ? `จำนวนหุ้น (หุ้น) ` : ` Number of Shares	`}
                </th>
                <th className="text-white">
                  {lang === "th" ? `% หุ้น ` : `Shareholding `}
                </th>
              </tr>

              <tr>
                <td className="tableW">1.</td>
                <td className="w-100">
                  {lang === "th"
                    ? `นายพงษ์เทพ วิชัยกุล `
                    : `Mr. Pongthep Vichaikul `}
                </td>
                <td>62,404,245 </td>
                <td>11.81 </td>
              </tr>

              <tr className="bg-gray">
                <td className="tableG">2.</td>
                <td>
                  {lang === "th"
                    ? `นายกิตติ พัวถาวรสกุล `
                    : `Mr. Kitti Phuathavornskul`}
                </td>
                <td>60,066,974 </td>
                <td>11.37</td>
              </tr>
              <tr>
                <td className="tableW">3.</td>
                <td>
                  {lang === "th"
                    ? `นายดนัยย์  ประดิษฐสุวรรณ์ `
                    : `Mr. Danai Praditsuwan  `}
                </td>
                <td>11,450,000 </td>
                <td>2.17</td>
              </tr>
              <tr className="bg-gray">
                <td className="tableG">4.</td>
                <td>
                  {lang === "th"
                    ? `น.ส.สิริลักษณ์ เดชธำรง `
                    : `Mrs. Siriluk Dejdumrong	 `}
                </td>
                <td>10,787,300 </td>
                <td>2.04</td>
              </tr>
              <tr>
                <td className="tableW">5.</td>
                <td>
                  {lang === "th"
                    ? `นายธีรพันธุ์ เหมภัสสร `
                    : ` Mr. Teeraphan Hempassorn`}
                </td>
                <td>9,000,000 </td>
                <td>1.70</td>
              </tr>
              <tr className="bg-gray">
                <td className="tableG">6.</td>
                <td>
                  {lang === "th" ? ` นายภัทร พลกุล` : `	Mr. Patr Bhalakula `}
                </td>
                <td>8,275,100 </td>
                <td>1.57</td>
              </tr>
              <tr>
                <td className="tableW">7.</td>
                <td>
                  {lang === "th"
                    ? `นายวีรวัฒน์ บูรพพัฒนพงศ์ `
                    : ` Mr. Weerapat Burapapattanapong	`}
                </td>
                <td>8,030,900 </td>
                <td>1.52</td>
              </tr>
              <tr className="bg-gray">
                <td className="tableG">8.</td>
                <td>
                  {lang === "th"
                    ? `นางละออ ตั้งคารวคุณ `
                    : ` Mrs. Laor Tangkaravakoon`}
                </td>
                <td>7,700,000 </td>
                <td>1.46</td>
              </tr>
              <tr>
                <td className="tableW">9.</td>
                <td>
                  {lang === "th"
                    ? `นายสุรศักดิ์ อุดมศิลป์ `
                    : `	Mr. Surasak Udomsilp`}
                </td>
                <td>7,080,000 </td>
                <td>1.34</td>
              </tr>
              <tr className="bg-gray">
                <td className="tableG">10. </td>
                <td>
                  {lang === "th"
                    ? ` บริษัท ไทยเอ็นวีดีอาร์ จำกัด`
                    : ` Thai NVDR Company Limited	`}
                </td>
                <td>6,630,155</td>
                <td>1.26</td>
              </tr>
            </table>
          </div>
        </div>
      </section>

      {/*------- นโยบายการจ่ายเงินปันผล ------------*/}
      <section className="section bg-Lgray ">
        <div className="container">
          <div className=" mx-auto" style={{ width: "95%" }}>
            <h3 className="fw-bold">
              {lang === "th"
                ? `นโยบายการจ่ายเงินปันผล`
                : `Dividend Payment Policy
`}
            </h3>
            <div className="row">
              <div className="col-xl-6 mt-5">
                <p>
                  {lang === "th"
                    ? `บริษัทฯ มีนโยบายจ่ายเงินปันผลในอัตราไม่ต่ำกว่าร้อยละ 50 ของกำไรสุทธิหลังหักทุนสำรองต่างๆ ทั้งหมดแล้ว ซึ่งการจ่ายเงินปันผลนั้นจะต้องไม่มีผลกระทบต่อการดำเนินงาน ฐานะการเงิน สภาพคล่อง การขยายธุรกิจ ความจำเป็น ความเหมาะสมอื่นใดในอนาคต และปัจจัยอื่นๆ ที่เกี่ยวข้องในการบริหารงานของบริษัทฯ ตามที่คณะกรรมการบริษัทพิจารณาเห็นสมควรหรือเหมาะสม ทั้งนี้ การดำเนินการดังกล่าวจะต้องก่อให้เกิดประโยชน์สูงสุดต่อผู้ถือหุ้น
 `
                    : `The Company’s dividend payment policy is to pay at least 50 percent of its net profit after the deduction of all reserves. Dividend payments must not adversely affect the Company’s operations, financial position, liquidity, business expansion, necessity, future suitability, or other factors relevant to the Company’s administration, as determined by the Board of Directors deems, and must yield maximum benefits to the shareholders.
 `}
                </p>
                <p>
                  {lang === "th"
                    ? `สำหรับนโยบายการจ่ายเงินปันผลของบริษัทย่อย บริษัทย่อยจะพิจารณาการจ่ายเงินปันผลจากกำไรสุทธิหลังจากหักภาษีเงินได้ในแต่ละปี อย่างไรก็ตามการจ่ายเงินปันผลจะขึ้นอยู่กับแผนการลงทุนและความเหมาะสมอื่นๆ และอาจจ่ายเงินปันผลระหว่างกาลให้แก่ผู้ถือหุ้นเป็นครั้งคราวได้`
                    : `The subsidiaries will consider paying dividends based on the net profit after the deduction of the corporate income tax each year. However, this will depend on the investment plan and other suitability considerations. The subsidiaries may pay interim dividends to their shareholders from time to time.
 `}
                </p>
                <p>
                  {lang === "th"
                    ? `ทั้งนี้ มติคณะกรรมการบริษัทซึ่งพิจารณาเรื่องการจ่ายเงินปันผลต้องนำเสนอที่ประชุมผู้ถือหุ้นเพื่อขออนุมัติ ยกเว้น กรณีการจ่ายเงินปันผลระหว่างกาล คณะกรรมการบริษัทมีอำนาจอนุมัติให้ดำเนินการได้ เมื่อเห็นว่า   บริษัทฯ มีกำไรสมควรพอที่จะจ่ายโดยไม่มีผลกระทบต่อการดำเนินงานของบริษัทฯ แล้วรายงานให้ที่ประชุมผู้ถือหุ้นทราบในการประชุมคราวต่อไป `
                    : `The Board of Directors’ resolution regarding dividend payments must be proposed at the shareholders’ meeting for approval. However, the Board of Directors has the authority to approve interim dividend payments, upon determining that the Company has sufficient profits to do so without posing any impact on its business operations. Said approval shall be reported at the subsequent shareholders’ meeting.
 `}
                </p>
              </div>
              <div className="col-xl-6">
                <div
                  id="BgBanner"
                  className=" mx-auto xl_mxauto"
                  style={{
                    backgroundImage:
                      "url(/images/shareholding/นักลงทุนสัมพันธ์-30.jpg)",
                    aspectRatio: "1/1",
                    height: "clamp(300px,50vw,500px)",
                  }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Information;
