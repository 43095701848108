import React from "react";

function SwapColor({ title, lists }) {
  // console.log(lists);
  return (
    <div className="color ">
      <div className="row container mx-auto py-1" style={{ width: "75%" }}>
        <p className="col-md-4 fw-bold m-0 ">{title}</p>
        <div className="col-md-8 ">
          {lists?.map((list, index) => (
            <p className="m-1">{list}</p>
          ))}
        </div>
      </div>
    </div>
  );
}

export default SwapColor;
