// import React from "react";
import { baseUrl } from "../../services/Environment";
import React, { useRef, useState, useEffect } from "react";
const isSafari = () => {
  const ua = navigator.userAgent.toLowerCase();
  return ua.indexOf("safari") > -1 && ua.indexOf("chrome") < 0;
};

const HeaderOverlay = ({ bannerURL, bannerALT }) => {
  const mainVideo = baseUrl + bannerURL;
  const videoParentRef = useRef();
  const [shouldUseImage, setShouldUseImage] = useState(false);
  useEffect(() => {
    if (isSafari() && videoParentRef.current) {
      const player = videoParentRef.current.children[0];
      if (player) {
        player.controls = false;
        player.playsinline = true;
        player.muted = true;
        player.setAttribute("muted", "");
        player.autoplay = true;
        setTimeout(() => {
          const promise = player.play();
          if (promise.then) {
            promise
              .then(() => {})
              .catch(() => {
                videoParentRef.current.style.display = "none";
                setShouldUseImage(true);
              });
          }
        }, 0);
      }
    }
  }, []);

  return shouldUseImage ? (
    <img src={mainVideo} alt="Muted Video" />
  ) : (
    <div className="overlay">
      {/* <video className="w-100" autoPlay muted loop playsinline >
          <source src={baseUrl + bannerURL} type="video/mp4" />
        </video> */}
      <div
        ref={videoParentRef}
        dangerouslySetInnerHTML={{
          __html: `
            <video
              loop
              muted
              autoplay
              playsinline
              preload="metadata"
              class="w-100"
            >
            <source src="${mainVideo}" type="video/mp4" />
            </video>`,
        }}
      />
    </div>
  );
};

export default HeaderOverlay;
