import React from "react";

function ViewPDF({ title, href, date, target, rel,url_link }) {
  console.log('url_link',url_link)
  if (title === "nodata") {
    return (
      <div className="bg-Lgray  pdfRes d-flex rounded-4 justify-content-between align-items-center mt-2 px-5 py-3">
        <div className=" d-flex gap-4 align-items-center">
          <div className="d-flex gap-4 xl-col">
            <h5 className="m-0 pdfHFont fw-bold" style={{ lineHeight: "1.4" }}>
              No Data
            </h5>
          </div>
        </div>
      </div>
    );
  }
  return (
  <>

      <div className="bg-Lgray pdfRes d-flex rounded-4 justify-content-between align-items-center mt-2 px-5 py-3">
        <div className="d-flex gap-4 align-items-center">
          <i
            className="fa-regular fa-file-lines fa-xl"
            style={{ color: "black" }}
          ></i>
          <div className="d-flex gap-4 xl-col">
            <h5
              className="my-auto pdfHFont fw-bold"
              style={{ lineHeight: "1.4" }}
            >
              {date}
            </h5>
            <h5 className="m-0 pdfHFont fw-bold" style={{ lineHeight: "1.4" }}>
              {title}
            </h5>
          </div>
        </div>
        <div className="d-flex gap-4 align-items-center ">
          {url_link && (
            
            <a
              href={url_link}
              className="d-flex pdfFont bg-white py-2 px-1 rounded-pill"
              style={{ height: "40px" }}
            >
              <div className="btn border-0 d-flex align-items-center text-black gap-2">
                <p className="pdfFont fw-bold text-nowrap m-0">View Video</p>
                <i
                  className="fa-solid fa-location-arrow"
                  style={{ color: "black" }}
                ></i>
              </div>
            </a>
          )}
          {href && (
            <a
              href={href}
              target={target}
              rel={rel}
              className="d-flex pdfFont bg-white py-2 px-1 rounded-pill"
              style={{ height: "40px" }}
            >
              <div className="btn border-0 d-flex align-items-center text-black gap-2">
                <p className="pdfFont fw-bold text-nowrap m-0">View PDF</p>
                <i
                  className="fa-solid fa-location-arrow"
                  style={{ color: "black" }}
                ></i>
              </div>
            </a>
          )}
        </div>
      </div>
    
  </>
);
}

export default ViewPDF;
