import React, { Fragment, useEffect, useState } from "react";
import { MenuNavbar, Seo } from "../components";
import { useTranslation } from "react-i18next";
import useGetPage from "../hooks/useGetPage";
import Footer from "../components/Footer/Footer";
import TimeLine from "../components/TimeLines/TimeLine";
import HeaderCard from "../components/TimeLines/HeaderCard";
import ContainerLogo from "../components/TimeLines/ContainerLogo";
import ReadMore from "../components/ReadMore/ReadMore";
import Card from "../components/TimeLines/Card";
import useStrAPI from "../hooks/useStrAPI";
import useStrAPIPage from "../hooks/useStrAPIPage";
import { baseUrl } from "../services/Environment";
import HeaderBannerOne from "../components/HeaderCore/HeaderBannerOne";
import Banner from "../components/HeaderCore/Banner";

function Aboutus() {
  const [lang, setLang] = useState();

  const { t, i18n } = useTranslation();
  const { GetDatas, loading, getStrAPI } = useStrAPI();
  const { PageAPI, loadingPage, getAPIPage } = useStrAPIPage();
  // const { executives, loading, getExecutives } = useGetExecutives();

  useEffect(() => {
    // console.log(localStorage.getItem("itemsHref"));
    if (localStorage.getItem("ncl_lang")) {
      setLang(localStorage.getItem("ncl_lang"));
      i18n.changeLanguage(lang);
    } else {
      setLang("th");
    }

    if (lang !== undefined) {
      getAPIPage(
        "about-ncl",
        lang,
        "&populate[banner][populate]=*&populate[board_of_directors][populate]=*&populate[ncl_business][populate]=*&populate[Info][populate]=*&populate[affiliated_companies][populate]=*&populate[item_ncl_business][populate]=*&populate[banner_line][populate]=*&populate[images_full][populate]=*"
      ); //populate
      getStrAPI("affiliated-companies", lang);
    }
  }, [lang]);

  const itemsHref = window.location.hash;
  if (
    itemsHref !== "" &&
    itemsHref !== "#" &&
    itemsHref !== "itemsHref" &&
    itemsHref !== undefined &&
    itemsHref !== null
  ) {
    const element = document.querySelector(itemsHref);
    if (element) {
      const offset = 80;
      const offsetTop = element.offsetTop - offset;
      window.scrollTo({
        top: offsetTop,
        behavior: "smooth", // เลื่อนโดยการ animate ให้เรียบร้อย
      });
    }
  }

  const handleLang = (event, attrLang) => {
    window.location.hash = "";
    let changeText = attrLang === "th" ? "en" : "th";
    localStorage.setItem("ncl_lang", changeText);
    setLang(localStorage.getItem("ncl_lang"));
    i18n.changeLanguage(lang);
  };
  // console.log("PAGE-ssss", GetDatas?.data.attributes,"about-ncl", lang,loading);
  // console.log("PAG888888E", GetDatas?.data);

  if (PageAPI) {
    return (
      <Fragment>
        <Seo
          title="Home"
          description="A description of the page"
          name="Company name"
          type="articlec"
          keywords="HTML, CSS, JavaScript"
        />
        <MenuNavbar>
          <div className="align-items-center icon-nav-custom ">
            <img
              src="images/icon/icon-12.png"
              alt={"NCL Language " + lang}
              // height="30"
              className="narbar_link"
              data-lang={lang}
              onClick={(event) =>
                handleLang(
                  event.target,
                  event.target.attributes.getNamedItem("data-lang").value
                )
              }
            />
            <span
              style={{ textTransform: "uppercase" }}
              className="pt-1 narbar_font"
              data-lang={lang}
              onClick={(event) =>
                handleLang(
                  event.target,
                  event.target.attributes.getNamedItem("data-lang").value
                )
              }
            >
              {lang}
            </span>
          </div>
        </MenuNavbar>
        {/* {GetDatas?.data.attributes.map(restaurant => ( */}

        <>
          {/* <li>{restaurant.ncl_business.title}</li> */}

          <HeaderBannerOne
            id={"BgBanner"}
            img={baseUrl + PageAPI?.data.attributes.banner.data.attributes.url}
            imgMobile={"/images/banner/AboutHeader-Mobile-01.jpg"}
            colorH1={"text-white"}
            colorP={"text-white"}
          />

          {/*---------- ธุรกิจ --------------*/}

          <div id="business" className="container section w-75 mobile_w90 ">
            <HeaderCard
              title={PageAPI?.data.attributes.ncl_business.title}
              content={PageAPI?.data.attributes.ncl_business.details}
            />
            {/* item_ncl_business  */}
            <div className="row m-0 p-0">
              {PageAPI?.data.attributes.item_ncl_business.map((item, index) => (
                // console.log(item.images.data !== null)
                // if(baseUrl + item.images.data.attributes.url){

                // }
                <ContainerLogo
                  img={
                    item.images.data == null
                      ? "/images/noimg.jpg"
                      : baseUrl + item.images.data.attributes.url
                  }
                  content={item.title}
                  textModal={item.details}
                  // titleModal={"การให้บริการจัดการขนส่งระหว่าง ประเทศ"}
                  // textModal={`เป็นผู้บริหำรจัดกำรให้เกิดกำรเคลื่อนย้ำยสินค้าจาก จุดขนถ่ายสินค้าเพื่อส่งออกจากประเทศไทยไปสู่ยังจุดหมาย ปลายทำงในประเทศต่าง ๆ กว่า 180 ประเทศครอบคลุมทุกเส้นทาง การค้าหลักทั่วโลก และการเป็นผู้บริหารจัดกำรกำรเคลื่อนย้าย
                  //    สินค้าจากประเทศต่าง ๆ มาสู่จุดขนถ่ายสินค้าเข้าประเทศ ซึ่งกลุ่ม บริษัทมีการให้บริกำรจัดการขนส่งทั้งทางทะเลและทางอากาศ`}
                  src={item.images_full.data == null
                      ? ""
                      : baseUrl + item.images_full.data.attributes.url}
                />
              ))}
            </div>
            {/* <ReadMore size={"fs-5"} /> */}
          </div>

          <h1 id="history" className="container fw-bold text-center pt-5">
            {lang === "th" ? `ความเป็นมาของบริษัท` : `Company History`}
            <hr className="border border-secondary" />
          </h1>
          <TimeLine data={PageAPI?.data?.attributes.Info} />
          {/*---------- สารจากกรรมการบริษัท --------------*/}
          {/* <div id="message" className="mb-5"></div> */}

          <section id="message">
            <div className="container pt-5 w-75 mobile_w90 ">
              <HeaderCard
                title={PageAPI?.data.attributes.board_of_directors[0].title}
                content={PageAPI?.data.attributes.board_of_directors[0].details}
              />
            </div>
            <Banner
              id="BgBanner"
              // style={{
              //   backgroundImage: `url(${
              //     baseUrl +
              //     PageAPI?.data.attributes.banner_line.data.attributes.url
              //   })`,
              //   height: "400px",
              // }}
              img={
                baseUrl +
                PageAPI?.data.attributes.banner_line.data.attributes.url
              }
              imgMobile={"/images/banner/AboutHeader-หน้าคั่น-02.jpg"}
            ></Banner>
          </section>

          {/*-------- บริษัทในเครือ ----------*/}

          <section id="partner">
            <div className="container section w-75 mobile_w90">
              <HeaderCard
                title={PageAPI?.data.attributes.affiliated_companies.title}
                content={PageAPI?.data.attributes.affiliated_companies.details}
              />
              <div className="row container m-0 p-0">
                {GetDatas?.data.map((item, index) => (
                  // <TimeLineItem
                  //   title={item.title}
                  //   subTitle={item.title_detail}
                  //   content={item.details}
                  //   key={index}
                  // />
                  <Card data={item.attributes} />
                ))}
              </div>
            </div>
          </section>
        </>

        {/* ))} */}
        <Footer />
      </Fragment>
    );
  }
}

export default Aboutus;
